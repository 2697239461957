<template>
  <div id="user-profile">
    <b-row class="mt-2">
      <b-col
        md="6"
      >
        <b-card>
          <b-card-text>
            <h4>Import Prayers</h4>
          </b-card-text>

          <b-col cols="12">
            <b-form-group
              label="Import File"
              label-for="file"
            >
              <b-form-file
                id="file"
                v-model="inpoPrayersTime.salwat"
                accept=".xlsx,.xls"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              :disabled="!(inpoPrayersTime.salwat&&inpoPrayersTime.entityId)"
              size="md"
              variant="primary"
              @click="storeSalawat"
            >
              Import
            </b-button>
          </b-col>
        </b-card>
      </b-col>
      <b-col
        md="6"
      >
        <b-card>
          <b-card-text>
            <h4>Export Prayers</h4>
          </b-card-text>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Start Date"
              >
                <flat-pickr
                  v-model="exportCriteria.startDate"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d'}"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="End Date"
              >
                <flat-pickr
                  v-model="exportCriteria.endDate"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d'}"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                :disabled="!(inpoPrayersTime.entityId && exportCriteria.startDate && exportCriteria.endDate)"
                size="md"
                variant="primary"
                :href="exportPrayers()"
              >
                Export
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import handleFormData from '@/common/compositions/common/handleFormData'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  components: {
    flatPickr,
  },
  setup() {
    const { formData, setFormData } = handleFormData()
    const { successfulOperationAlert, showErrors } = handleAlerts()

    return {
      formData,
      setFormData,
      successfulOperationAlert,
      showErrors,
    }
  },
  data() {
    return {
      exportCriteria: {
        startDate: null,
        endDate: null,
      },
      inpoPrayersTime: {
        salwat: null,
        entityId: this.$store.getters['mainEntity/getEntityId'],
      },
    }
  },
  methods: {
    exportPrayers() {
      return `${process.env.VUE_APP_PRAYERS_BASE_URL}/export-prayers?id=${this.inpoPrayersTime.entityId}&from=${this.exportCriteria.startDate}&to=${this.exportCriteria.endDate}`
    },
    storeSalawat() {
      this.setFormData(this.inpoPrayersTime)
      this.$http.post(`${process.env.VUE_APP_PRAYERS_BASE_URL}/store`, this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.successfulOperationAlert('Salawat is imported successfully')
      }).catch(err => {
        const errors = Object.values(err.response.data.errors)
        errors.forEach(error => {
          this.showErrors(error)
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-profile.scss';
</style>
